import React from 'react';
import { useRouter } from 'next/router';
import { ModalContextType, ModalType, useModalContext } from 'src/hooks/useModal';
import { MenuList } from '../../../ui-kit/MenuList/MenuList';
import { ROUTES } from '../../primitives/Link';
import { ScrollModal } from '../../primitives/ScrollModal';
import { TxModalTitle } from '../FlowCommons/TxModalTitle';
import { ACTIONS_LIST } from './constants';
import { EActionType } from './types';
import { EControlPositionType } from '../../../types/uniswapTokens';

export const ActionsModal = () => {
  const router = useRouter();
  const {
    openAddLiquidityPosition,
    openCompoundPosition,
    openDeleveragePosition,
    openClosePosition,
    openClaimFeesPosition,
  } = useModalContext();
  const { type, args, close } = useModalContext() as ModalContextType<{
    positionAddress: string;
    controlType: EControlPositionType;
  }>;

  const actionsList = ACTIONS_LIST.filter(
    (item) => item.controlType?.includes(args.controlType)
  );

  const handleOnClick = (index: number) => {
    switch (actionsList[index].type) {
      case EActionType.AddLiquidity:
        window.gtag('event', 'leverage_add_liquidity_click');
        if (args.positionAddress) openAddLiquidityPosition(args.positionAddress);
        return;
      case EActionType.CompoundFees:
        window.gtag('event', 'leverage_compound_fees_click');
        if (args.positionAddress) openCompoundPosition(args.positionAddress);
        return;
      case EActionType.ClaimFees:
        window.gtag('event', 'leverage_claim_fees_click');
        if (args.positionAddress) openClaimFeesPosition(args.positionAddress);
        return;
      case EActionType.RebalancePosition:
        window.gtag('event', 'rebalance_position_click');
        if (args.positionAddress) router.push(ROUTES.rebalancePosition(args.positionAddress));
        close();
        return;
      case EActionType.DeleveragePosition:
        window.gtag('event', 'leverage_deleverage_click');
        if (args.positionAddress) openDeleveragePosition(args.positionAddress, args.controlType);
        return;
      case EActionType.ClosePosition:
        window.gtag('event', 'close_position_click');
        if (args.positionAddress) openClosePosition(args.positionAddress, args.controlType);
        return;
    }
  };

  return (
    <ScrollModal open={type === ModalType.Actions} setOpen={close}>
      <TxModalTitle title="Select action" sx={{ ml: 6 }} />
      <MenuList items={actionsList} onSelect={handleOnClick} />
    </ScrollModal>
  );
};
